import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";

const routes = constructRoutes(document.querySelector("#single-spa-layout"), {
  loaders: {
    navLoader: "<h1></h1>",
    workflowLoader: "",
    chatbotLoader: "",
    userLoader: "",
  },
  errors: {
    navError: "<h1>Unable to load Nav Bar</h1>",
    workflowError: "<h1>Unable to load Workflow Frontend</h1>",
    chatbotError: "<h1>Unable to load Chatbot Frontend</h1>",
    userError: "<h1>Unable to load User Frontend</h1>",
  },
});
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);

System.import("@idflow/styles").then(() => {
  // Activate the layout engine once the styleguide CSS is loaded
  layoutEngine.activate();
  start();
});
// layoutEngine.activate();
// start();
